import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import styles from './index.module.less';
import { Paragraph } from 'components/Typography';
import brochure from 'images/brochure.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="About Us"
      meta={[
        {
          name: 'description',
          content: 'Who the trust helps and what assistance is provided.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="About Us"
      intro="Ryhaven Charitable Trust assists limited income home owners to repair and upgrade their home."
    />
    <Grid fluid>
      <Row>
        <Col md={8} className={styles.main}>
          <Paragraph>Ryhaven has identified a particular need for assistance in the Wellington area.</Paragraph>
          <Paragraph>We aim to assist families who own their own home but lack the resources to maintain it in a safe and habitable state.</Paragraph>
          <Paragraph>The Ryhaven Trust provides interest free loans for building works carried out by the Trust's team and associated trades people. Any repayments contribute to the continuation of the Ryhaven Trust and its ability to help other people in similar circumstances. The Ryhaven Trust makes every effort to make these works affordable.</Paragraph>
          <Paragraph>To reduce costs, families are asked to participate in improvements with works such as painting, wallpaper stripping and tidying the property. <em>Instruction and materials are provided.</em></Paragraph>
          <Paragraph>The Ryhaven Trust has an assessment process and the Trust will determine the appropriate assistance needed on a case-by-case basis. Eligibility is determined by the scale and necessity of improvements, ability to meet repayments, and the Ryhaven Trusts' planning of its calendar of works.</Paragraph>
          <Paragraph>If you would like the trust's assistance, please <Link to="/apply">apply online</Link>. For any other enquiries, please <Link to="/contact-us">contact us</Link></Paragraph>
        </Col>
        <Col sm={4} className={styles.sidebar}>
          <Paragraph>
            <a className={styles.brochure} href="https://www.ryhaven.org.nz/Ryhaven-Trust-Brochure.pdf">
              <img className={styles.image} src={brochure} alt="" />
              Download our brochure
            </a>
          </Paragraph>
          <Paragraph>
            If you would like a printed copy sent, please <Link to="/contact-us">contact us</Link>
          </Paragraph>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
